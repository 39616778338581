import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  defaultTo,
  filter,
  find,
  intersection,
  is,
  map,
  omit,
  path,
  pathOr,
  pick,
  propEq,
} from 'ramda';
import * as prospectActions from './actions';
import moment from 'moment';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { SizedBox } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import TextingTab from '../TextingTab';
import TextingTabIcon from '../TextingTabIcon';
import {
  getAllActivityTypes,
  getAllContactTypes,
  getAllFloorPlans,
  getAllPetBreeds,
  getAllPetTypes,
  getAllProspectStatus,
  getAllReferralTypes,
  getProspectAssignees,
  updateSelectedProperty,
} from '../App/actions';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';

import ProfileDetails from './ProfileDetails';
import ProfileDetailsEditMode from './ProfileDetails/editMode';
import Snapshot from './Snapshot';
import Household from './Household';
import ProspectInfoTab from './ProspectInfoTab';
import {
  recordActivity,
  scheduleActivity,
} from '../../components/ActivityModal';
import { createActivity } from '../../components/CreateActivityModal';
import confirm from '../../components/ConfirmDialogModal';
import messages from './messages';
import { editCompletedActivity } from '../../components/EditCompletedActivityModal';
import { editPendingActivity } from '../../components/EditPendingActivityModal';
import ProspectInfoTabEditMode from './ProspectInfoTabEditMode';
import PersonalInformationModal, {
  PERSONAL_INFORMATION_FORM,
} from '../../components/PersonalInformationModal';
import ActivityTableDeprecated from '../../components/ActivityTableDeprecated';
import ActivityTypeService from '../../services/activityTypeService';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import type {
  ActivityType,
  ContactType,
  GlobalState,
  PaginationMeta,
  PetBreed,
  PetType,
  ProspectStatus,
  ReferralType,
  User,
} from '../App/types';

import type {
  Activity,
  FormProspect,
  Prospect,
} from '../ProspectProfile/types';
import type { StatusChange } from './types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ActivityContainer from '../../components/ActivityContents';
import {
  getProspectToSubmit,
  nBedsArrInitialValueBuilder,
} from '../../utils/prospectPreferences-helpers';
import { getDesiredMoveInDate } from '../ManageProspects/utils';

type Props = {
  currentProspectId: string,
  currentProspect?: Prospect,
  users: Array<User>,
  activityTypes: Array<ActivityType>,
  prospectStatusList: Array<ProspectStatus>,
  locale: string,
  petTypes: Array<PetType>,
  petBreeds: Array<PetBreed>,
  contactTypes: Array<ContactType>,
  referralTypes: Array<ReferralType>,
  pendingActivities: Array<Activity>,
  completedActivities: Array<Activity>,
  completedActivitiesMeta: PaginationMeta,
  allActivities: Array<Activity>,
  allActivitiesMeta: PaginationMeta,
  columnOrder: Object,
  currentUser: any,
  selectedProperty: ?Object,
  relationships: Array<Object>,
};

type InjectedProps = {
  intl: any,
  actions: Object,
  currentProspect: Prospect,
  history: Object,
  location: Object,
};

type State = {
  activityHistoryField: string,
  activityHistoryOrder: string,
  activityHistoryCurrentPage: number,
  activityHistoryLimit: number,
  editMode: boolean,
  editModeProspectInfo: boolean,
  editModeRequiresNote: boolean,
  scheduleActivityModal: boolean,
  modal: ?string,
  personToEdit: Object,
  activityTableUpdateTrigger: boolean,
};

export class PeopleProfile extends Component<Props & InjectedProps, State> {
  constructor(props: Props & InjectedProps) {
    super(props);
    const searchParams = new URLSearchParams(props.location.search);
    this.state = {
      activityHistoryField: 'dateTime',
      activityHistoryOrder: 'DESC',
      activityHistoryPageCount: 0,
      activityHistoryCurrentPage: 1,
      activityHistoryCurrentCount: 0,
      activityHistoryTotalCount: 0,
      activityHistoryLimit: 10,
      editMode: false,
      editModeProspectInfo: false,
      editModeRequiresNote: false,
      scheduleActivityModal: false,
      modal: null,
      personToEdit: {},
      key: searchParams.get('tab') ?? '1',
      activityTableUpdateTrigger: false, // tells activity table to re-query activities
    };
  }

  componentDidMount() {
    this.props.actions.getProspectAssignees();
    this.props.actions.getAllPetTypes();
    this.props.actions.getAllPetBreeds();
    this.props.actions.getAllContactTypes();
    this.props.actions.getAllReferralTypes();
    this.props.actions.getAllActivityTypes();
    this.props.actions.getAllFloorPlans();
    this.props.actions.getAllProspectStatus();
    this.props.actions.getOneProspect(this.props.currentProspectId);
    this.props.actions.updateSelectedProperty();
    this.props.actions.getProspectPendingActivities(
      this.props.currentProspectId,
    );
    this.props.actions.updateColumnsSortValue('dateTime', 'descending');
    this.refreshActivities();
  }

  componentDidUpdate(prevProps) {
    // To trigger a refresh in the ActivityTable
    const prospectStatusHasChanged =
      typeof prevProps.currentProspect?.currentProspectStatus?.id ===
        'string' &&
      typeof this.props.currentProspect?.currentProspectStatus?.id ===
        'string' &&
      prevProps.currentProspect?.currentProspectStatus?.id !==
        this.props.currentProspect?.currentProspectStatus?.id;

    const prospectAssignedToHasChanged =
      typeof prevProps.currentProspect?.assignedTo?.id === 'string' &&
      typeof this.props.currentProspect?.assignedTo?.id === 'string' &&
      prevProps.currentProspect?.assignedTo?.id !==
        this.props.currentProspect?.assignedTo?.id;

    if (prospectAssignedToHasChanged || prospectStatusHasChanged) {
      this.setState({
        activityTableUpdateTrigger: !this.state.activityTableUpdateTrigger,
      });
    }
    const prospectCreated = pathOr(
      undefined,
      ['state', 'wasJustCreated'],
      this.props.location,
    );
    if (
      !this.state.scheduleActivityModal &&
      this.props.currentProspect &&
      prospectCreated
    ) {
      this.setState({
        scheduleActivityModal: true,
      });
      this.onCreateActivityClick();
    }
  }

  refreshActivityTable = () => {
    this.setState(
      {
        activityTableUpdateTrigger: !this.state.activityTableUpdateTrigger,
      },
      () => this.refreshActivities(),
    );
  };

  onActivityHistoryOrder = (field: string) => {
    const columnOrder = this.props.columnOrder[field];
    const order = columnOrder === 'ascending' ? 'DESC' : 'ASC';
    const icon =
      columnOrder === 'sortable'
        ? 'ascending'
        : columnOrder === 'ascending'
        ? 'descending'
        : 'ascending';
    this.props.actions.updateColumnsSortValue(field, icon);
    this.setState({
      activityHistoryField: field,
      activityHistoryOrder: order,
    });
    this.props.actions.getProspectAllActivities(
      this.props.currentProspectId,
      this.state.activityHistoryCurrentPage,
      this.state.activityHistoryLimit,
      field,
      order,
    );
    this.props.actions.getProspectCompletedActivities(
      this.props.currentProspectId,
      this.state.activityHistoryCurrentPage,
      this.state.activityHistoryLimit,
      field,
      order,
    );
  };

  checkUserPermissions(testScope: Array<string>) {
    const userScopes = this.props.currentUser.permissions.map(
      (permission) => permission.scope,
    );
    return intersection(testScope, userScopes).length > 0;
  }

  refreshActivities() {
    this.props.actions.getProspectAllActivities(
      this.props.currentProspectId,
      this.state.activityHistoryCurrentPage,
      this.state.activityHistoryLimit,
      this.state.activityHistoryField,
      this.state.activityHistoryOrder,
    );
    this.props.actions.getProspectCompletedActivities(
      this.props.currentProspectId,
      this.state.activityHistoryCurrentPage,
      this.state.activityHistoryLimit,
      this.state.activityHistoryField,
      this.state.activityHistoryOrder,
    );
  }

  onActivityHistoryPageChange(pageNumber: number) {
    this.setState(
      {
        activityHistoryCurrentPage: pageNumber,
      },
      () => this.refreshActivities(),
    );
  }

  generateUsers() {
    return this.props && this.props.users
      ? this.props.users.map((user) => ({
          value: user.id,
          text: `${user.firstName} ${user.lastName}`,
          disabled: false,
        }))
      : [];
  }

  generateProspectStatusList = () => {
    return this.props.prospectStatusList.map((status) => ({
      value: status.id,
      text: status.translations[this.props.locale] || status.name,
      requiresNote: status.requiresNote,
    }));
  };
  onRecordActivityClick = () => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.generateUsers();
    /* istanbul ignore else */
    if (this.props.currentProspect != null) {
      recordActivity(
        this.context.store,
        this.props.intl,
        this.props.currentProspect,
        activityTypesList,
        users,
        'prospect',
        this.props.currentProspectId,
      ).then((data: Activity) => {
        this.props.actions.createProspectActivity(
          Object.assign(data, { customerStatus: 'Prospect' }),
        );
        this.setState({
          activityHistoryCurrentPage: 1,
        });
      });
    }
  };

  navigateToManageProspects = () => {
    const selectedPropertyId = this.props.selectedProperty?.id ?? '';
    if (!selectedPropertyId) {
      this.props.history.push('select-property');
    }
    navigateToUrlWithSelectedPropertyId('/management');
  };

  onHandleDismiss = (dismiss: Function) => {
    const prospectCreated = pathOr(
      undefined,
      ['state', 'wasJustCreated'],
      this.props.location,
    );
    if (prospectCreated) {
      this.navigateToManageProspects();
    }
    dismiss();
  };

  onScheduleActivityClick = () => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.generateUsers();
    const prospectCreated =
      this.props.location?.state?.wasJustCreated ?? undefined;
    const currentProspect = prospectCreated
      ? this.props.location?.state?.currentProspect ?? undefined
      : this.props.currentProspect;

    if (currentProspect != null) {
      scheduleActivity(
        this.context.store,
        this.props.intl,
        currentProspect,
        activityTypesList,
        users,
        'prospect',
        this.props.currentProspectId,
        this.onHandleDismiss,
      ).then((data: Activity) => {
        this.props.actions.createProspectActivity(
          Object.assign(data, { customerStatus: 'Prospect' }),
        );
        this.setState({
          activityHistoryCurrentPage: 1,
        });
        if (prospectCreated) {
          this.navigateToManageProspects();
        }
      });
    }
  };

  onCreateActivityClick = async (refresh?: Function) => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.generateUsers();
    const prospectCreated =
      this.props.location?.state?.wasJustCreated ?? undefined;
    const currentProspect = prospectCreated
      ? this.props.location?.state?.currentProspect ?? undefined
      : this.props.currentProspect;

    if (currentProspect != null) {
      const data: Activity = await createActivity({
        store: this.context.store,
        intl: this.props.intl,
        prospect: currentProspect,
        activityTypesList: activityTypesList,
        assigneeList: users,
        stage: 'prospect',
        urlId: this.props.currentProspectId,
        onHandleDismiss: this.onHandleDismiss,
      });

      this.props.actions.createProspectActivity(
        {
          ...data,
          customerStatus: 'Prospect',
        },
        refresh,
      );
      this.setState({
        activityHistoryCurrentPage: 1,
      });
      if (prospectCreated) {
        this.navigateToManageProspects();
      }
    }
  };

  onEditActivity = (activity: Activity, refresh?: Function) => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.generateUsers();
    const stageInfo = {
      currentStage: 'prospect',
      urlId: this.props.currentProspectId,
    };

    const editPromise = activity.activityCompletionStatusId
      ? editCompletedActivity(
          this.context.store,
          this.props.intl,
          activity,
          stageInfo,
        )
      : editPendingActivity(
          this.context.store,
          this.props.intl,
          activity,
          activityTypesList,
          users,
          this.props.currentProspect,
          stageInfo,
        );

    editPromise.then((data: Activity) => {
      if (data.saveAndClose) {
        this.props.actions.updateProspectActivity(data, refresh);
      } else if (data.saveAndAddNew) {
        this.props.actions.updateProspectActivity(data, refresh);
        this.onCreateActivityClick(this.refreshActivityTable);
      } else {
        this.props.actions.deleteProspectActivity(data, refresh);
      }
      this.setState({
        activityHistoryCurrentPage: 1,
      });
    });
  };

  handleEdit = () => {
    this.state.editMode
      ? this.setState({ editMode: false })
      : this.setState({ editMode: true });
  };

  handleProspectInfoEdit = () => {
    this.state.editModeProspectInfo
      ? this.setState({ editModeProspectInfo: false })
      : this.setState({ editModeProspectInfo: true });
  };

  handleNewStatusChange = (event: Object, newStatusId: string) => {
    const selectedNewStatus = find(propEq('id', newStatusId))(
      this.props.prospectStatusList,
    );
    if (!selectedNewStatus || !this.props.currentProspect) return;
    this.setState({
      editModeRequiresNote:
        selectedNewStatus.id !== this.props.currentProspect.prospectStatusId &&
        selectedNewStatus.requiresNote,
    });
  };

  handleUpdateContactInformation = ({
    prospect,
    statusChange,
  }: {
    prospect: Prospect,
    statusChange: StatusChange,
  }) => {
    const setNullToEmptyStrings = (x) => (x === '' ? null : x);
    // $FlowFixMe
    prospect = map(setNullToEmptyStrings, prospect);
    const prospectToUpdate = omit(['portalUser'], prospect);
    this.props.actions.updateProspect(prospectToUpdate, statusChange);
    this.setState({
      editMode: false,
      activityHistoryCurrentPage: 1,
    });
  };

  generateRelationships(locale: string) {
    const relationships = this.props.relationships
      ? this.props.relationships.map((suffix) => ({
          value: suffix.id,
          text: suffix.translations[locale] || suffix.name,
        }))
      : [];
    relationships.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return relationships;
  }

  handleSubmitProspectInfoTab = (prospect: FormProspect) => {
    const relationships = this.generateRelationships(this.props.intl.locale);
    const currentProspect = this.props.currentProspect;
    const prospectToSubmit = getProspectToSubmit(
      {
        prospect,
        currentProspect,
        propertyId: currentProspect.propertyId,
        relationships,
      },
      this.props.flags,
    );
    this.props.actions.updateProspect(prospectToSubmit, null);
    this.setState({
      editModeProspectInfo: false,
      activityHistoryCurrentPage: 1,
    });
  };

  handleAssignedToChange = (event: Object, newUserId: string) => {
    this.props.actions.assignProspect({
      ...this.props.currentProspect,
      assignedToId: newUserId,
    });
  };

  handleConvertToApplicant = () => {
    if (this.props.currentProspectId.length) {
      const route = `/create-application/${this.props.currentProspectId}`;
      navigateToUrlWithSelectedPropertyId(route);
    }
  };

  handleEditHousehold = () => {
    const route = `/prospect/${defaultTo('')(
      path(['currentProspect', 'id'], this.props),
    )}/household`;
    navigateToUrlWithSelectedPropertyId(route);
  };

  handleCancel = (pristine: boolean) => {
    if (pristine) {
      this.setState({ editMode: false });
    } else {
      confirm(this.props.intl.formatMessage(messages.cancelConfirmation), {
        intl: this.props.intl,
      }).then(
        () => {
          this.setState({ editMode: false });
        },
        () => {
          this.setState({ editMode: true });
        },
      );
    }
  };

  handleCancelProspectInfoTab = (pristine: boolean) => {
    if (pristine) {
      this.setState({ editModeProspectInfo: false });
    } else {
      confirm(this.props.intl.formatMessage(messages.cancelConfirmation), {
        intl: this.props.intl,
      }).then(
        () => {
          this.setState({ editModeProspectInfo: false });
        },
        () => {
          this.setState({ editModeProspectInfo: true });
        },
      );
    }
  };

  getDropDownOptions = (list: Array<ContactType | ReferralType>) => {
    const options: Array<Object> = list
      .map((item) => ({
        value: item.id,
        text: item.translations[this.props.locale] || item.name,
      }))
      .sort((a: Object, b: Object) => a.text.localeCompare(b.text));
    options.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return options;
  };

  getDropDownOptionsForPets = (list: Array<PetType>): Array<Object> =>
    list.map((item) => ({
      value: item.id,
      text: item.translations[this.props.locale] || item.name,
      breeds: item.petBreeds.map((itemBreed) => ({
        value: itemBreed.id,
        text: itemBreed.translations[this.props.locale] || itemBreed.name,
      })),
    }));

  getProspectForForm = (): FormProspect | {} => {
    if (!this.props.currentProspect) return {};
    const prospect = this.props.currentProspect;
    // $FlowFixMe
    const objP = pick(
      [
        'firstName',
        'lastName',
        'emailAddress',
        'phoneNumber',
        'contactTypeId',
        'referralTypeId',
        'assignedTo',
        'prospectStatusId',
      ],
      this.props.currentProspect,
    );
    const spouse = filter(
      propEq('type', 'Spouse'),
      prospect.additionalOccupants,
    );
    const kids = filter(propEq('type', 'Minor'), prospect.additionalOccupants);
    const otherAdults = filter(
      propEq('type', 'Other Adult'),
      prospect.additionalOccupants,
    );
    // $FlowFixMe
    const preferences = pathOr({}, ['prospectPreferences'], prospect);
    const { moveInDateFrom, moveInDateTo } = getDesiredMoveInDate({
      prospectPreferences: prospect?.prospectPreferences,
    });

    const initialProspect = {
      ...objP,
      spouse: spouse,
      otherAdults: otherAdults,
      kids: kids,
      pets: prospect.petOccupants,
      spouseChecked: spouse.length > 0,
      otherAdultsChecked: otherAdults.length > 0,
      otherAdultsAmount: otherAdults.length,
      kidsChecked: kids.length > 0,
      kidsAmount: kids.length,
      petsChecked: prospect.petOccupants.length > 0,
      petsAmount: prospect.petOccupants.length,
      nBaths: preferences.nBaths,
      nHalfBaths: preferences.nHalfBaths,
      nBeds: preferences.nBeds,
      nBedsArr: nBedsArrInitialValueBuilder(
        preferences.nBedsArr,
        preferences.nBeds,
      ),
      moveInDateFrom: this.props.flags.assigningUnitsMoveInDates
        ? moveInDateFrom
        : preferences.moveInDateFrom
        ? moment(preferences.moveInDateFrom)
        : null,
      moveInDateTo: this.props.flags.assigningUnitsMoveInDates
        ? moveInDateTo
        : preferences.moveInDateTo
        ? moment(preferences.moveInDateTo)
        : null,
      moveInDateScheduled: preferences.moveInDateScheduled,
      unitId: preferences.unitId,
      priceFrom: preferences.priceFrom,
      priceTo: preferences.priceTo,
      leaseTermInMonths: preferences.leaseTermInMonths,
      helpText: preferences.helpText,
      wants: preferences.wants,
      notWants: preferences.notWants,
      noMoveInDate: Boolean(!moveInDateFrom),
      preferredFloorPlanId: preferences.preferredFloorPlanId,
      prefersUnitAccessible: preferences.prefersUnitAccessible ? 'yes' : 'no',
    };
    return initialProspect;
  };

  getLatestLog(prospect?: Prospect) {
    return prospect &&
      prospect.hasOwnProperty('prospectStatusChangeLog') &&
      is(Array, prospect.prospectStatusChangeLog) &&
      prospect.prospectStatusChangeLog.length > 0
      ? prospect.prospectStatusChangeLog.sort(
          (a, b) => +moment(b.updatedAt) - +moment(a.updatedAt),
        )[0]
      : null;
  }

  resetModal = () => {
    this.setState({ modal: null });
  };

  editHouseholdMember = (member: Object) => {
    const { id, firstName, lastName, emailAddress, portalUser } = member;
    this.setState({
      personToEdit: {
        prospectId: id,
        name: `${firstName} ${lastName}`,
        emailAddress,
        customerStatusTitle: 'Prospect',
        portalUser,
      },
      modal: PERSONAL_INFORMATION_FORM,
    });
  };

  emailProspectPortalInvite = (prospectId: string) => {
    this.props.actions.sendProspectPortalInviteEmail(prospectId);
  };

  render() {
    const { currentProspect, selectedProperty, currentProspectId } = this.props;
    const prospectStatusList = this.generateProspectStatusList();
    const users = this.generateUsers();
    const latestLog = this.getLatestLog(this.props.currentProspect);

    if (!currentProspect) {
      return <AsyncBox loading={true} sx={{ height: 'calc(100vh - 40px)' }} />;
    }

    const isResidentPortalActive = pathOr(
      false,
      ['isResidentPortalActive'],
      selectedProperty,
    );

    const showTextingTab =
      selectedProperty.isTwoWayCommunicationActive &&
      this.checkUserPermissions(['communication-create']);

    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <Fragment>
          <PersonalInformationModal
            // TODO: Make it so that we can edit applicant's info
            // onSubmit={this.handleEditHouseholdMemeberSubmit}
            personalInfo={this.state.personToEdit}
            onClose={this.resetModal}
            show={this.state.modal === PERSONAL_INFORMATION_FORM}
            residentPortalInfo={{
              display: isResidentPortalActive,
              sendPortalInviteEmail: this.emailProspectPortalInvite,
            }}
            isCommercial={false}
          />
          <Grid fluid>
            <Row>
              <Col xs={12} md={5} className="people-profile-left">
                {this.state.editMode && (
                  <ProfileDetailsEditMode
                    intl={this.props.intl}
                    initialValues={{
                      prospect: this.props.currentProspect,
                      statusChange: {
                        statusId: currentProspect.prospectStatusId,
                      },
                    }}
                    latestLog={latestLog}
                    handleEdit={this.handleEdit}
                    onSubmit={this.handleUpdateContactInformation}
                    prospectStatusList={prospectStatusList}
                    requiresNote={this.state.editModeRequiresNote}
                    handleNewStatusChange={this.handleNewStatusChange}
                    handleCancel={this.handleCancel}
                  />
                )}
                {!this.state.editMode && (
                  <ProfileDetails
                    intl={this.props.intl}
                    currentRecord={currentProspect}
                    handleEdit={this.handleEdit}
                    latestLog={latestLog}
                  />
                )}
                <Snapshot
                  initialValues={{
                    assignedToId: currentProspect.assignedToId,
                  }}
                  currentRecord={this.props.currentProspect}
                  numberOfScheduledContacts={
                    this.props.pendingActivities.length
                  }
                  numberOfCompletedContacts={
                    this.props.completedActivitiesMeta.totalCount
                  }
                  users={users}
                  handleAssignedToChange={this.handleAssignedToChange}
                  handleConvertToApplicant={this.handleConvertToApplicant}
                  history={this.props.history}
                />
                <Household
                  additionalOccupants={currentProspect.additionalOccupants}
                  currentProspect={currentProspect}
                  petOccupants={currentProspect.petOccupants}
                  onEdit={this.handleEditHousehold}
                  editHouseholdMember={this.editHouseholdMember}
                  isResidentPortalActive={isResidentPortalActive}
                />
              </Col>
              <Col xs={12} md={7} className="people-profile-right">
                <Panel className="block block-white-shadow">
                  <Panel.Body>
                    <Tabs
                      id="peoples-tab"
                      activeKey={this.state.key}
                      onSelect={(key) => this.setState({ ...this.state, key })}
                    >
                      <Tab
                        eventKey="1"
                        title={
                          <FormattedMessage {...messages.prospectInformation} />
                        }
                      >
                        {!this.state.editModeProspectInfo && (
                          <ProspectInfoTab
                            intl={this.props.intl}
                            handleProspectInfoEdit={this.handleProspectInfoEdit}
                            prospect={currentProspect}
                            petTypes={this.props.petTypes}
                            contactTypes={this.props.contactTypes}
                            referralTypes={this.props.referralTypes}
                            floorPlans={this.props.floorPlans}
                          />
                        )}
                        {this.state.editModeProspectInfo && (
                          <ProspectInfoTabEditMode
                            intl={this.props.intl}
                            handleProspectInfoEdit={this.handleProspectInfoEdit}
                            petTypes={this.getDropDownOptionsForPets(
                              this.props.petTypes,
                            )}
                            contactTypes={this.getDropDownOptions(
                              this.props.contactTypes,
                            )}
                            referralTypes={this.getDropDownOptions(
                              this.props.referralTypes,
                            )}
                            initialValues={this.getProspectForForm()}
                            onSubmit={this.handleSubmitProspectInfoTab}
                            handleCancel={this.handleCancelProspectInfoTab}
                            floorPlans={this.props.floorPlans}
                          />
                        )}
                      </Tab>
                      <Tab
                        eventKey="2"
                        title={<FormattedMessage {...messages.activityTab} />}
                        disabled={!this.checkUserPermissions(['activity-read'])}
                      >
                        {this.props.flags
                          .activityFilterHideAutomatedActivitiesCheckbox ? (
                          <ActivityContainer
                            intl={this.props.intl}
                            prospectId={this.props.currentProspectId}
                            locale={this.props.locale}
                            onEditActivity={this.onEditActivity}
                            onCreateActivityClick={this.onCreateActivityClick}
                            updateTrigger={
                              this.state.activityTableUpdateTrigger
                            }
                          />
                        ) : (
                          <ActivityTableDeprecated
                            intl={this.props.intl}
                            selectedProperty={this.props.selectedProperty}
                            prospectId={this.props.currentProspectId}
                            activities={this.props.allActivities}
                            locale={this.props.locale}
                            onEditActivity={this.onEditActivity}
                            onCreateActivityClick={this.onCreateActivityClick}
                            updateTrigger={
                              this.state.activityTableUpdateTrigger
                            }
                          />
                        )}
                      </Tab>
                      {showTextingTab && (
                        <Tab
                          eventKey="texting"
                          title={
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {this.props.intl.formatMessage(messages.texting)}{' '}
                              <SizedBox w={3} />
                              <TextingTabIcon prospectId={currentProspectId} />
                            </span>
                          }
                        >
                          <Panel.Body>
                            <TextingTab prospectId={currentProspectId} />
                          </Panel.Body>
                        </Tab>
                      )}
                    </Tabs>
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
        </Fragment>
      </DocumentTitle>
    );
  }
}

PeopleProfile.contextTypes = {
  store: PropTypes.any,
};

export const mapStateToProps = (
  { app, peopleProfile, languageProvider }: GlobalState,
  ownProps: Object,
): Props => {
  return {
    currentProspectId: ownProps.match.params.prospectId,
    currentProspect: peopleProfile.currentProspect,
    users: app.prospectAssignees,
    activityTypes: app.activityTypes,
    petTypes: app.petTypes,
    petBreeds: app.petBreeds,
    contactTypes: app.contactTypes,
    referralTypes: app.referralTypes,
    prospectStatusList: app.prospectStatusList,
    locale: languageProvider.locale,
    pendingActivities: peopleProfile.pendingActivities,
    completedActivities: peopleProfile.completedActivities,
    completedActivitiesMeta: peopleProfile.completedActivitiesMeta,
    allActivities: peopleProfile.allActivities,
    allActivitiesMeta: peopleProfile.allActivitiesMeta,
    columnOrder: peopleProfile.columnOrder,
    currentUser: app.currentUser,
    selectedProperty: app.selectedProperty,
    floorPlans: app.floorPlans,
    relationships: app.relationships,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...prospectActions,
      getProspectAssignees,
      getAllActivityTypes,
      getAllPetTypes,
      getAllPetBreeds,
      getAllReferralTypes,
      getAllContactTypes,
      getAllFloorPlans,
      getAllProspectStatus,
      updateSelectedProperty,
    },
    dispatch,
  );
  return { actions };
}

const InjectedPeopleProfile = injectIntl(PeopleProfile);

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(InjectedPeopleProfile),
);
