import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { keys, pathOr, omit } from 'ramda';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import RecertificationApplicationForm from './RecertificationApplicationForm';
import { studentsTypes } from '../PrimaryLeaseApplication/AffordablePrimaryForm/configuration';
import * as recertificationActions from './actions';
import { getAllStates, getAllEmploymentStatuses } from '../App/actions';
import {
  getStateOptions,
  getEmploymentStatusesOptions,
} from '../App/selectors';
import { HUD_PROGRAM_NAME } from '../AffordableQualificationTab/Shared/utils';
import { INITIAL_CERT_TYPE } from '../AffordableQualificationTab/HUDTab/constants';

import AffordableQualificationService from '../../services/affordableQualificationService';
import { COMPLIANCE_APPROVAL_IDS_TO_DISABLE_FORMS } from '../QualificationForms/constants';
import {
  Spinner,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { FormattedMessage } from 'react-intl';
import messages from './messages.js';

import type { GlobalState, SelectOption } from '../App/types';

type Props = {
  actions: Object,
  intl: Object,
  history: Object,
  change: Function,
  selectedProperty: Object,
  employmentStatuses: Array<SelectOption>,
  states: Array<SelectOption>,
  submit: Function,
  recertificationFormData: Object,
  residentId: string,
  affordableQualificationId: string,
  applicantId: string,
  applicationId: string,
  householdId: string,
  programName: string,
};
type State = {};

export class RecertificationApplication extends Component<State & Props> {
  constructor() {
    super();
    this.state = {
      affordableQualification: {},
      isLoading: true,
      isError: false,
    };
  }
  async componentDidMount() {
    const {
      affordableQualificationId,
      applicantId,
      actions: {
        getAllStates,
        getAllEmploymentStatuses,
        getRecertificationFormInformation,
      },
      selectedProperty: { id: propertyId, organizationId },
    } = this.props;

    getAllStates();
    getAllEmploymentStatuses();
    getRecertificationFormInformation(applicantId, affordableQualificationId);
    await this.fetchAffordableQualification(
      organizationId,
      propertyId,
      affordableQualificationId,
    );
  }

  componentWillUnmount() {
    this.props.actions.cleanRecertificationFormInformation();
  }

  getIsInitialCertForNonHUD = (): boolean => {
    const { selectedProperty, programName, flags, recertificationFormData } =
      this.props;
    const { affordableQualificationCertificationType } =
      recertificationFormData;

    // TODO: NOAH and DF LOAN
    const requiresInitialCertificationForNonHUD =
      selectedProperty?.setup?.requiresInitialCertificationForNonHUD ?? false;
    const requiresLIHTCInitialCertFlag =
      (flags?.initialCertsForLihtc ?? false) &&
      requiresInitialCertificationForNonHUD;
    const isNonHUDProgram = programName !== HUD_PROGRAM_NAME;
    return (
      affordableQualificationCertificationType === INITIAL_CERT_TYPE &&
      isNonHUDProgram &&
      requiresLIHTCInitialCertFlag
    );
  };

  fetchAffordableQualification = async (
    organizationId,
    propertyId,
    affordableQualificationId,
  ) => {
    try {
      const aqService = new AffordableQualificationService();
      const affordableQual = await aqService.getById(
        organizationId,
        propertyId,
        affordableQualificationId,
      );

      this.setState({
        affordableQualification: affordableQual,
        isLoading: false,
        isError: false,
      });
    } catch (error) {
      this.setState({
        affordableQualification: {},
        isLoading: false,
        isError: true,
      });
    }
  };

  handleCancel = () => {
    const { residentId, programName, selectedProperty } = this.props;
    const urlProgramName = (programName ?? '').replace(/\//g, '_');
    if (programName) {
      this.props.history.push(
        `/property/${selectedProperty.id}/resident/${residentId}/?tab=${urlProgramName}`,
      );
    } else {
      this.props.history.push(
        `/property/${selectedProperty.id}/resident/${residentId}/?tab=7`,
      );
    }
  };

  handleSubmit = (formData: Object) => {
    const massageEmployerInfo = (employerInfo: Object): Object => {
      const { phoneNumber, address } = employerInfo;
      let employerAddress;
      if (address) {
        employerAddress = {
          employerStreetAddress: address.street,
          ...omit(['street'], address),
        };
      }
      const employerObject = omit(['phoneNumber', 'address'], employerInfo);
      const setNulls = (obj: Object) => (withNulls, key) =>
        obj[key] === ''
          ? { ...withNulls, [key]: null }
          : { ...withNulls, [key]: obj[key] };
      const finalEmployerObject = keys(employerObject).reduce(
        setNulls(employerObject),
        {},
      );
      const finalEmployerAddress = employerAddress
        ? keys(employerAddress).reduce(setNulls(employerAddress), {})
        : null;
      return {
        ...finalEmployerObject,
        ...finalEmployerAddress,
        employerPhone: phoneNumber,
      };
    };

    const {
      actions,
      applicantId,
      residentId,
      applicationId,
      programName,
      affordableQualificationId,
    } = this.props;

    const {
      applicantsCurrentEmployer,
      applicantsSecondCurrentEmployer,
      applicantsPreviousEmployer,
      studentInformation,
      employmentInformation,
      hasSignedCopy,
      isComplete,
    } = formData;

    const isInitialCertForNonHUD = this.getIsInitialCertForNonHUD();

    const yesNoDict = { yes: true, no: false };

    const changeHousehold = pathOr(
      null,
      ['changeHouseholdInformation'],
      formData,
    );
    const anticipateChangeHouseholdString = pathOr(
      null,
      ['changeHouseholdInformation', 'anticipateChangeHousehold'],
      formData,
    );
    const anticipateChangeHousehold = anticipateChangeHouseholdString
      ? yesNoDict[anticipateChangeHouseholdString]
      : null;
    const changeHouseholdInformation = changeHousehold
      ? {
          ...changeHousehold,
          anticipateChangeHousehold,
        }
      : null;

    const currentEmployer = massageEmployerInfo(applicantsCurrentEmployer);
    const secondCurrentEmployer = massageEmployerInfo(
      applicantsSecondCurrentEmployer,
    );
    const previousEmployer = massageEmployerInfo(applicantsPreviousEmployer);
    const employmentStatusId = employmentInformation
      ? employmentInformation.status
      : null;

    const anticipateChangeEmployerString = pathOr(
      null,
      ['anticipateChangeEmployer'],
      employmentInformation,
    );
    const anticipateChangeEmployer = anticipateChangeEmployerString
      ? yesNoDict[anticipateChangeEmployerString]
      : null;

    const recertFormData = {
      applicantsCurrentEmployer: currentEmployer,
      applicantsSecondCurrentEmployer: secondCurrentEmployer,
      applicantsPreviousEmployer: previousEmployer,
      employmentStatusId,
      studentInformation,
      changeHouseholdInformation,
      hasSignedCopy,
      anticipateChangeEmployer,
      isComplete,
      isInitialCertForNonHUD,
    };
    actions.saveRecertificationForm(
      recertFormData,
      applicantId,
      residentId,
      applicationId,
      programName,
      affordableQualificationId,
    );
  };

  handlePrintApplicationForm = () => {
    const {
      applicantId,
      affordableQualificationId,
      programName,
      selectedProperty: { id: propertyId, organizationId, name: propertyName },
    } = this.props;
    this.props.actions.printRecertificationApplicationForm(
      organizationId,
      propertyId,
      applicantId,
      affordableQualificationId,
      programName,
      propertyName,
    );
  };

  render() {
    const {
      intl,
      change,
      applicantId,
      householdId,
      selectedProperty,
      employmentStatuses,
      states,
      recertificationFormData,
      affordableQualificationId,
      selectedProperty: { id: propertyId, organizationId },
    } = this.props;
    const { isLoading, isError, affordableQualification } = this.state;

    const isComplete = pathOr('', ['isComplete'], recertificationFormData);
    const propertyName = pathOr('', ['name'], selectedProperty);
    const isInitialCertForNonHUD = this.getIsInitialCertForNonHUD();
    const isFormDisabled = COMPLIANCE_APPROVAL_IDS_TO_DISABLE_FORMS.includes(
      affordableQualification?.complianceApprovalId,
    );
    return isError ? (
      <Grid container align="center" justify="center">
        <Grid item xs={12}>
          <Typography sx={{ fontSize: '12px' }}>
            <FormattedMessage {...messages.loadError} />
            <button
              onClick={() => {
                this.fetchAffordableQualification(
                  organizationId,
                  propertyId,
                  affordableQualificationId,
                );
              }}
            >
              <FormattedMessage {...messages.retry} />
            </button>
          </Typography>
        </Grid>
      </Grid>
    ) : isLoading ? (
      <Spinner />
    ) : (
      <RecertificationApplicationForm
        intl={intl}
        change={change}
        applicantId={applicantId}
        householdId={householdId}
        employmentStatuses={employmentStatuses}
        states={states}
        propertyName={propertyName}
        handleCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        studentsTypes={studentsTypes}
        initialValues={recertificationFormData}
        isComplete={isComplete}
        handlePrintRecertApplicationForm={this.handlePrintApplicationForm}
        isInitialCertForNonHUD={isInitialCertForNonHUD}
        isFormDisabled={isFormDisabled}
      />
    );
  }
}

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): Object => {
  const { app, recertificationFormData } = state;
  return {
    affordableQualificationId: ownProps.match.params.affordableQualificationId,
    applicantId: ownProps.match.params.applicantId,
    residentId: ownProps.match.params.residentId,
    applicationId: ownProps.match.params.applicationId,
    householdId: ownProps.match.params.householdId,
    programName: ownProps.match.params.programName,
    employmentStatuses: getEmploymentStatusesOptions(state),
    states: getStateOptions(state),
    selectedProperty: app.selectedProperty,
    recertificationFormData,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...recertificationActions,
      getAllStates,
      getAllEmploymentStatuses,
    },
    dispatch,
  );
  return { actions };
}

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(RecertificationApplication));

export default withLDConsumer()(connected);
