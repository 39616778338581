import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, Field } from 'redux-form';

// Components
import styled from 'styled-components';
import { Panel, Row, Col } from 'react-bootstrap';
import {
  Button,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import {
  Header,
  Title,
  Divider,
  DetailList,
} from '../../../components/ProfileVisualComponents';
import IconTitle from '../../../components/ProfileVisualComponents/IconTitle';
import FormattedDateOrDashes from '../../../components/FormattedDateOrDashes';

// Hooks
import useAffordableWaitlistValidations from '../../ManageAffordableWaitlist/hooks.useAffordableWaitlistValidations';

// Types
import type { Prospect } from '../../ProspectProfile/types';

// Utils
import { findIndex, sortBy } from 'ramda';

// Constants
import messages from './messages';

type Props = {
  currentRecord: Prospect,
  numberOfScheduledContacts: number,
  numberOfCompletedContacts: number,
  users: Array<Object>,
  handleAssignedToChange: Function,
  handleConvertToApplicant: Function,
  history: {
    push: Function,
  },
  intl: Object,
};

const SnapshotPanel = styled(Panel).attrs({
  className: 'block block-snapshot block-white-shadow',
})``;

const LabelProfileDetails = styled.label.attrs({
  className: 'profile-details',
})``;

export const Snapshot = ({
  currentRecord,
  numberOfScheduledContacts,
  numberOfCompletedContacts,
  users,
  handleAssignedToChange,
  handleConvertToApplicant,
  history,
  intl,
}: Props) => {
  const nextActivity = currentRecord.nextActivity || { startTime: undefined };

  const {
    affordableWaitlistFlag,
    hasAffordableWaitlistPermissionAdd,
    accountThisPropertyForWaitlist,
    affordableWaitlistPropertySetting,
    isAffordableWaitlistPropertySettingStatusClosed,
  } = useAffordableWaitlistValidations();
  const convertDisabled =
    affordableWaitlistFlag &&
    accountThisPropertyForWaitlist &&
    affordableWaitlistPropertySetting &&
    (!hasAffordableWaitlistPermissionAdd ||
      isAffordableWaitlistPropertySettingStatusClosed);

  const assignees = useMemo(() => {
    const assignee = currentRecord.assignedTo;

    // $FlowFixMe
    const assigneeIndex = findIndex(
      (x) => x.value === currentRecord.assignedToId,
      users,
    );

    const options =
      assigneeIndex < 0 && assignee
        ? sortBy(
            (x) => {
              return x.text.toLowerCase();
            },
            [
              {
                value: currentRecord.assignedToId,
                text: `${assignee.firstName} ${assignee.lastName}`,
              },
            ].concat(users),
          )
        : users;

    if (!currentRecord.assignedTo) {
      options.unshift({
        value: '',
        text: intl.formatMessage(messages.unassigned),
        disabled: true,
      });
    }
    return options;
  }, [currentRecord.assignedTo, currentRecord.assignedToId, users, intl]);

  const ConvertToApplicantButton = () => {
    const buttonProps = {
      variant: 'action',
      disabled: convertDisabled,
      children: <FormattedMessage {...messages.convertToApplicant} />,
    };
    let tooltipMessage = null;

    if (convertDisabled) {
      if (!hasAffordableWaitlistPermissionAdd) {
        tooltipMessage = intl.formatMessage(messages.waitlistNoPermission);
      } else if (isAffordableWaitlistPropertySettingStatusClosed) {
        tooltipMessage = intl.formatMessage(messages.waitlistClosed);
      }
    }

    return tooltipMessage ? (
      <Tooltip title={tooltipMessage} placement={'top'}>
        <Button {...buttonProps} />
      </Tooltip>
    ) : (
      <Button {...buttonProps} onClick={handleConvertToApplicant} />
    );
  };

  return (
    <SnapshotPanel>
      <Header>
        <Title>
          <IconTitle
            icon={<i key="1" className="et-prospect" />}
            message={messages.snapshot}
          />
        </Title>
      </Header>
      <Panel.Body>
        <Row className="row">
          <Col xs={12} md={3}>
            <LabelProfileDetails>
              <FormattedMessage {...messages.assignedTo} />
            </LabelProfileDetails>
          </Col>
          <Col xs={12} md={8}>
            <ElementWithPermissions scope={['prospect-assign']}>
              <Field
                name="assignedToId"
                component="select"
                className="form-control input-sm"
                onChange={handleAssignedToChange}
              >
                {assignees.map((user) => (
                  <option
                    key={user.value}
                    value={user.value}
                    disabled={user.disabled}
                  >
                    {user.text}
                  </option>
                ))}
              </Field>
            </ElementWithPermissions>
          </Col>
        </Row>
        <Divider />
        <DetailList>
          <li>
            <FormattedMessage {...messages.prospectCreationDate} />{' '}
            <FormattedDateOrDashes
              value={currentRecord.createdAt}
              format="MMM DD, YYYY"
            />
          </li>
          <li>
            <FormattedMessage {...messages.nextScheduledActivity} />{' '}
            {
              <FormattedDateOrDashes
                value={nextActivity.startTime}
                format="MMM DD, YYYY"
              />
            }
          </li>
          <li>
            <FormattedMessage {...messages.numberOfScheduledContacts} />
            {` ${numberOfScheduledContacts}`}
          </li>
          <li>
            <FormattedMessage {...messages.numberOfCompletedContacts} />
            {` ${numberOfCompletedContacts}`}
          </li>
          <li>
            <FormattedMessage {...messages.tour} />
            {` ${currentRecord.tourStatus}`}
          </li>
        </DetailList>
        <Row>
          <Col xs={12} sm={6}>
            <Button
              variant={'primarySubtle'}
              onClick={() =>
                history.push(
                  `/property/${currentRecord.propertyId}/manage-unit-availability?prospectId=${currentRecord.id}`,
                )
              }
              type="button"
            >
              <FormattedMessage {...messages.provideQuote} />
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <ConvertToApplicantButton />
          </Col>
        </Row>
      </Panel.Body>
    </SnapshotPanel>
  );
};

export default reduxForm({
  form: 'snapshot',
  enableReinitialize: true,
})(injectIntl(Snapshot));
